import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "./assets/scss/style.scss";
import VueCarousel from "vue-carousel";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";

Vue.use(CoolLightBox);
Vue.use(VueCarousel);
Vue.use(VModal);
Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
